enifed('ember-runtime/mixins/array', ['exports', 'ember-utils', 'ember-metal', 'ember-debug', 'ember-runtime/mixins/enumerable'], function (exports, _emberUtils, _emberMetal, _emberDebug, _enumerable) {
  'use strict';

  exports.addArrayObserver = addArrayObserver;
  exports.removeArrayObserver = removeArrayObserver;
  exports.objectAt = objectAt;
  exports.arrayContentWillChange = arrayContentWillChange;
  exports.arrayContentDidChange = arrayContentDidChange;
  exports.isEmberArray = isEmberArray;

  var _Mixin$create;

  function arrayObserversHelper(obj, target, opts, operation, notify) {
    var willChange = opts && opts.willChange || 'arrayWillChange';
    var didChange = opts && opts.didChange || 'arrayDidChange';
    var hasObservers = (0, _emberMetal.get)(obj, 'hasArrayObservers');

    if (hasObservers === notify) {
      (0, _emberMetal.propertyWillChange)(obj, 'hasArrayObservers');
    }

    operation(obj, '@array:before', target, willChange);
    operation(obj, '@array:change', target, didChange);

    if (hasObservers === notify) {
      (0, _emberMetal.propertyDidChange)(obj, 'hasArrayObservers');
    }

    return obj;
  }

  function addArrayObserver(array, target, opts) {
    return arrayObserversHelper(array, target, opts, _emberMetal.addListener, false);
  }

  function removeArrayObserver(array, target, opts) {
    return arrayObserversHelper(array, target, opts, _emberMetal.removeListener, true);
  }

  function objectAt(content, idx) {
    if (content.objectAt) {
      return content.objectAt(idx);
    }

    return content[idx];
  }

  function arrayContentWillChange(array, startIdx, removeAmt, addAmt) {
    var removing = void 0,
        lim = void 0;

    // if no args are passed assume everything changes
    if (startIdx === undefined) {
      startIdx = 0;
      removeAmt = addAmt = -1;
    } else {
      if (removeAmt === undefined) {
        removeAmt = -1;
      }

      if (addAmt === undefined) {
        addAmt = -1;
      }
    }

    if (array.__each) {
      array.__each.arrayWillChange(array, startIdx, removeAmt, addAmt);
    }

    (0, _emberMetal.sendEvent)(array, '@array:before', [array, startIdx, removeAmt, addAmt]);

    if (startIdx >= 0 && removeAmt >= 0 && (0, _emberMetal.get)(array, 'hasEnumerableObservers')) {
      removing = [];
      lim = startIdx + removeAmt;

      for (var idx = startIdx; idx < lim; idx++) {
        removing.push(objectAt(array, idx));
      }
    } else {
      removing = removeAmt;
    }

    array.enumerableContentWillChange(removing, addAmt);

    return array;
  }

  function arrayContentDidChange(array, startIdx, removeAmt, addAmt) {
    // if no args are passed assume everything changes
    if (startIdx === undefined) {
      startIdx = 0;
      removeAmt = addAmt = -1;
    } else {
      if (removeAmt === undefined) {
        removeAmt = -1;
      }

      if (addAmt === undefined) {
        addAmt = -1;
      }
    }

    var adding = void 0;
    if (startIdx >= 0 && addAmt >= 0 && (0, _emberMetal.get)(array, 'hasEnumerableObservers')) {
      adding = [];
      var lim = startIdx + addAmt;

      for (var idx = startIdx; idx < lim; idx++) {
        adding.push(objectAt(array, idx));
      }
    } else {
      adding = addAmt;
    }

    array.enumerableContentDidChange(removeAmt, adding);

    if (array.__each) {
      array.__each.arrayDidChange(array, startIdx, removeAmt, addAmt);
    }

    (0, _emberMetal.sendEvent)(array, '@array:change', [array, startIdx, removeAmt, addAmt]);

    var meta = (0, _emberMetal.peekMeta)(array);
    var cache = meta && meta.readableCache();
    if (cache !== undefined) {
      var length = (0, _emberMetal.get)(array, 'length');
      var addedAmount = addAmt === -1 ? 0 : addAmt;
      var removedAmount = removeAmt === -1 ? 0 : removeAmt;
      var delta = addedAmount - removedAmount;
      var previousLength = length - delta;

      var normalStartIdx = startIdx < 0 ? previousLength + startIdx : startIdx;
      if (cache.firstObject !== undefined && normalStartIdx === 0) {
        (0, _emberMetal.propertyWillChange)(array, 'firstObject');
        (0, _emberMetal.propertyDidChange)(array, 'firstObject');
      }

      if (cache.lastObject !== undefined) {
        var previousLastIndex = previousLength - 1;
        var lastAffectedIndex = normalStartIdx + removedAmount;
        if (previousLastIndex < lastAffectedIndex) {
          (0, _emberMetal.propertyWillChange)(array, 'lastObject');
          (0, _emberMetal.propertyDidChange)(array, 'lastObject');
        }
      }
    }

    return array;
  }

  var EMBER_ARRAY = (0, _emberUtils.symbol)('EMBER_ARRAY');

  function isEmberArray(obj) {
    return obj && !!obj[EMBER_ARRAY];
  }

  // ..........................................................
  // ARRAY
  //
  /**
    This mixin implements Observer-friendly Array-like behavior. It is not a
    concrete implementation, but it can be used up by other classes that want
    to appear like arrays.
  
    For example, ArrayProxy is a concrete classes that can
    be instantiated to implement array-like behavior. Both of these classes use
    the Array Mixin by way of the MutableArray mixin, which allows observable
    changes to be made to the underlying array.
  
    Unlike `Ember.Enumerable,` this mixin defines methods specifically for
    collections that provide index-ordered access to their contents. When you
    are designing code that needs to accept any kind of Array-like object, you
    should use these methods instead of Array primitives because these will
    properly notify observers of changes to the array.
  
    Although these methods are efficient, they do add a layer of indirection to
    your application so it is a good idea to use them only when you need the
    flexibility of using both true JavaScript arrays and "virtual" arrays such
    as controllers and collections.
  
    You can use the methods defined in this module to access and modify array
    contents in a KVO-friendly way. You can also be notified whenever the
    membership of an array changes by using `.observes('myArray.[]')`.
  
    To support `Ember.Array` in your own class, you must override two
    primitives to use it: `length()` and `objectAt()`.
  
    Note that the Ember.Array mixin also incorporates the `Ember.Enumerable`
    mixin. All `Ember.Array`-like objects are also enumerable.
  
    @class Array
    @namespace Ember
    @uses Ember.Enumerable
    @since Ember 0.9.0
    @public
  */
  var ArrayMixin = _emberMetal.Mixin.create(_enumerable.default, (_Mixin$create = {}, _Mixin$create[EMBER_ARRAY] = true, _Mixin$create.length = null, _Mixin$create.objectAt = function (idx) {
    if (idx < 0 || idx >= (0, _emberMetal.get)(this, 'length')) {
      return undefined;
    }

    return (0, _emberMetal.get)(this, idx);
  }, _Mixin$create.objectsAt = function (indexes) {
    var _this = this;

    return indexes.map(function (idx) {
      return objectAt(_this, idx);
    });
  }, _Mixin$create.nextObject = function (idx) {
    return objectAt(this, idx);
  }, _Mixin$create['[]'] = (0, _emberMetal.computed)({
    get: function (key) {
      return this;
    },
    set: function (key, value) {
      this.replace(0, (0, _emberMetal.get)(this, 'length'), value);
      return this;
    }
  }), _Mixin$create.firstObject = (0, _emberMetal.computed)(function () {
    return objectAt(this, 0);
  }).readOnly(), _Mixin$create.lastObject = (0, _emberMetal.computed)(function () {
    return objectAt(this, (0, _emberMetal.get)(this, 'length') - 1);
  }).readOnly(), _Mixin$create.contains = function (obj) {
    (true && !(false) && (0, _emberDebug.deprecate)('`Enumerable#contains` is deprecated, use `Enumerable#includes` instead.', false, { id: 'ember-runtime.enumerable-contains', until: '3.0.0', url: 'https://emberjs.com/deprecations/v2.x#toc_enumerable-contains' }));


    return this.indexOf(obj) >= 0;
  }, _Mixin$create.slice = function (beginIndex, endIndex) {
    var ret = _emberMetal.default.A();
    var length = (0, _emberMetal.get)(this, 'length');

    if ((0, _emberMetal.isNone)(beginIndex)) {
      beginIndex = 0;
    }

    if ((0, _emberMetal.isNone)(endIndex) || endIndex > length) {
      endIndex = length;
    }

    if (beginIndex < 0) {
      beginIndex = length + beginIndex;
    }

    if (endIndex < 0) {
      endIndex = length + endIndex;
    }

    while (beginIndex < endIndex) {
      ret[ret.length] = objectAt(this, beginIndex++);
    }

    return ret;
  }, _Mixin$create.indexOf = function (object, startAt) {
    var len = (0, _emberMetal.get)(this, 'length');

    if (startAt === undefined) {
      startAt = 0;
    }

    if (startAt < 0) {
      startAt += len;
    }

    for (var idx = startAt; idx < len; idx++) {
      if (objectAt(this, idx) === object) {
        return idx;
      }
    }

    return -1;
  }, _Mixin$create.lastIndexOf = function (object, startAt) {
    var len = (0, _emberMetal.get)(this, 'length');

    if (startAt === undefined || startAt >= len) {
      startAt = len - 1;
    }

    if (startAt < 0) {
      startAt += len;
    }

    for (var idx = startAt; idx >= 0; idx--) {
      if (objectAt(this, idx) === object) {
        return idx;
      }
    }

    return -1;
  }, _Mixin$create.addArrayObserver = function (target, opts) {
    return addArrayObserver(this, target, opts);
  }, _Mixin$create.removeArrayObserver = function (target, opts) {
    return removeArrayObserver(this, target, opts);
  }, _Mixin$create.hasArrayObservers = (0, _emberMetal.computed)(function () {
    return (0, _emberMetal.hasListeners)(this, '@array:change') || (0, _emberMetal.hasListeners)(this, '@array:before');
  }), _Mixin$create.arrayContentWillChange = function (startIdx, removeAmt, addAmt) {
    return arrayContentWillChange(this, startIdx, removeAmt, addAmt);
  }, _Mixin$create.arrayContentDidChange = function (startIdx, removeAmt, addAmt) {
    return arrayContentDidChange(this, startIdx, removeAmt, addAmt);
  }, _Mixin$create.includes = function (obj, startAt) {
    var len = (0, _emberMetal.get)(this, 'length');

    if (startAt === undefined) {
      startAt = 0;
    }

    if (startAt < 0) {
      startAt += len;
    }

    for (var idx = startAt; idx < len; idx++) {
      var currentObj = objectAt(this, idx);

      // SameValueZero comparison (NaN !== NaN)
      if (obj === currentObj || obj !== obj && currentObj !== currentObj) {
        return true;
      }
    }

    return false;
  }, _Mixin$create['@each'] = (0, _emberMetal.computed)(function () {
    // TODO use Symbol or add to meta
    if (!this.__each) {
      this.__each = new EachProxy(this);
    }

    return this.__each;
  }).volatile().readOnly(), _Mixin$create));

  /**
    This is the object instance returned when you get the `@each` property on an
    array. It uses the unknownProperty handler to automatically create
    EachArray instances for property names.
    @class EachProxy
    @private
  */
  function EachProxy(content) {
    this._content = content;
    this._keys = undefined;
    (0, _emberMetal.meta)(this);
  }

  EachProxy.prototype = {
    __defineNonEnumerable: function (property) {
      this[property.name] = property.descriptor.value;
    },
    arrayWillChange: function (content, idx, removedCnt, addedCnt) {
      var keys = this._keys;
      var lim = removedCnt > 0 ? idx + removedCnt : -1;
      var meta = void 0;
      for (var key in keys) {
        meta = meta || (0, _emberMetal.peekMeta)(this);
        if (lim > 0) {
          removeObserverForContentKey(content, key, this, idx, lim);
        }
        (0, _emberMetal.propertyWillChange)(this, key, meta);
      }
    },
    arrayDidChange: function (content, idx, removedCnt, addedCnt) {
      var keys = this._keys;
      var lim = addedCnt > 0 ? idx + addedCnt : -1;
      var meta = void 0;
      for (var key in keys) {
        meta = meta || (0, _emberMetal.peekMeta)(this);
        if (lim > 0) {
          addObserverForContentKey(content, key, this, idx, lim);
        }
        (0, _emberMetal.propertyDidChange)(this, key, meta);
      }
    },
    willWatchProperty: function (property) {
      this.beginObservingContentKey(property);
    },
    didUnwatchProperty: function (property) {
      this.stopObservingContentKey(property);
    },
    beginObservingContentKey: function (keyName) {
      var keys = this._keys;
      if (!keys) {
        keys = this._keys = Object.create(null);
      }

      if (!keys[keyName]) {
        keys[keyName] = 1;
        var content = this._content;
        var len = (0, _emberMetal.get)(content, 'length');

        addObserverForContentKey(content, keyName, this, 0, len);
      } else {
        keys[keyName]++;
      }
    },
    stopObservingContentKey: function (keyName) {
      var keys = this._keys;
      if (keys && keys[keyName] > 0 && --keys[keyName] <= 0) {
        var content = this._content;
        var len = (0, _emberMetal.get)(content, 'length');

        removeObserverForContentKey(content, keyName, this, 0, len);
      }
    },
    contentKeyWillChange: function (obj, keyName) {
      (0, _emberMetal.propertyWillChange)(this, keyName);
    },
    contentKeyDidChange: function (obj, keyName) {
      (0, _emberMetal.propertyDidChange)(this, keyName);
    }
  };

  function addObserverForContentKey(content, keyName, proxy, idx, loc) {
    while (--loc >= idx) {
      var item = objectAt(content, loc);
      if (item) {
        (true && !(typeof item === 'object') && (0, _emberDebug.assert)('When using @each to observe the array ' + content + ', the array must return an object', typeof item === 'object'));

        (0, _emberMetal._addBeforeObserver)(item, keyName, proxy, 'contentKeyWillChange');
        (0, _emberMetal.addObserver)(item, keyName, proxy, 'contentKeyDidChange');
      }
    }
  }

  function removeObserverForContentKey(content, keyName, proxy, idx, loc) {
    while (--loc >= idx) {
      var item = objectAt(content, loc);
      if (item) {
        (0, _emberMetal._removeBeforeObserver)(item, keyName, proxy, 'contentKeyWillChange');
        (0, _emberMetal.removeObserver)(item, keyName, proxy, 'contentKeyDidChange');
      }
    }
  }

  exports.default = ArrayMixin;
});