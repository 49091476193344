enifed('ember-routing/services/router', ['exports', 'ember-runtime', 'ember-utils', 'ember-routing/system/dsl'], function (exports, _emberRuntime, _emberUtils, _dsl) {
  'use strict';

  function shallowEqual(a, b) {
    var k = void 0;
    for (k in a) {
      if (a.hasOwnProperty(k) && a[k] !== b[k]) {
        return false;
      }
    }
    for (k in b) {
      if (b.hasOwnProperty(k) && a[k] !== b[k]) {
        return false;
      }
    }
    return true;
  }

  /**
     The Router service is the public API that provides component/view layer
     access to the router.
  
     @public
     @class RouterService
     @category ember-routing-router-service
   */
  /**
  @module ember
  @submodule ember-routing
  */

  var RouterService = _emberRuntime.Service.extend({
    currentRouteName: (0, _emberRuntime.readOnly)('_router.currentRouteName'),
    currentURL: (0, _emberRuntime.readOnly)('_router.currentURL'),
    location: (0, _emberRuntime.readOnly)('_router.location'),
    rootURL: (0, _emberRuntime.readOnly)('_router.rootURL'),
    _router: null,

    transitionTo: function () {
      var queryParams = void 0;

      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      var arg = args[0];
      if (resemblesURL(arg)) {
        return this._router._doURLTransition('transitionTo', arg);
      }

      var possibleQueryParams = args[args.length - 1];
      if (possibleQueryParams && possibleQueryParams.hasOwnProperty('queryParams')) {
        queryParams = args.pop().queryParams;
      } else {
        queryParams = {};
      }

      var targetRouteName = args.shift();
      var transition = this._router._doTransition(targetRouteName, args, queryParams, true);
      transition._keepDefaultQueryParamValues = true;

      return transition;
    },
    replaceWith: function () /* routeNameOrUrl, ...models, options */{
      return this.transitionTo.apply(this, arguments).method('replace');
    },
    urlFor: function () /* routeName, ...models, options */{
      var _router;

      return (_router = this._router).generate.apply(_router, arguments);
    },
    isActive: function () /* routeName, ...models, options */{
      var _extractArguments = this._extractArguments.apply(this, arguments),
          routeName = _extractArguments.routeName,
          models = _extractArguments.models,
          queryParams = _extractArguments.queryParams;

      var routerMicrolib = this._router._routerMicrolib;
      var state = routerMicrolib.state;

      if (!routerMicrolib.isActiveIntent(routeName, models, null)) {
        return false;
      }
      var hasQueryParams = Object.keys(queryParams).length > 0;

      if (hasQueryParams) {
        this._router._prepareQueryParams(routeName, models, queryParams, true /* fromRouterService */);
        return shallowEqual(queryParams, state.queryParams);
      }

      return true;
    },
    _extractArguments: function (routeName) {
      for (var _len2 = arguments.length, models = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        models[_key2 - 1] = arguments[_key2];
      }

      var possibleQueryParams = models[models.length - 1];
      var queryParams = {};

      if (possibleQueryParams && possibleQueryParams.hasOwnProperty('queryParams')) {
        var options = models.pop();
        queryParams = options.queryParams;
      }

      return { routeName: routeName, models: models, queryParams: queryParams };
    }
  });

  function resemblesURL(str) {
    return typeof str === 'string' && (str === '' || str[0] === '/');
  }

  exports.default = RouterService;
});