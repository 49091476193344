enifed('ember-runtime/system/array_proxy', ['exports', 'ember-metal', 'ember-runtime/utils', 'ember-runtime/system/object', 'ember-runtime/mixins/mutable_array', 'ember-runtime/mixins/enumerable', 'ember-runtime/mixins/array', 'ember-debug'], function (exports, _emberMetal, _utils, _object, _mutable_array, _enumerable, _array, _emberDebug) {
  'use strict';

  /**
  @module ember
  @submodule ember-runtime
  */

  var OUT_OF_RANGE_EXCEPTION = 'Index out of range';
  var EMPTY = [];

  function K() {
    return this;
  }

  /**
    An ArrayProxy wraps any other object that implements `Ember.Array` and/or
    `Ember.MutableArray,` forwarding all requests. This makes it very useful for
    a number of binding use cases or other cases where being able to swap
    out the underlying array is useful.
  
    A simple example of usage:
  
    ```javascript
    let pets = ['dog', 'cat', 'fish'];
    let ap = Ember.ArrayProxy.create({ content: Ember.A(pets) });
  
    ap.get('firstObject');                        // 'dog'
    ap.set('content', ['amoeba', 'paramecium']);
    ap.get('firstObject');                        // 'amoeba'
    ```
  
    This class can also be useful as a layer to transform the contents of
    an array, as they are accessed. This can be done by overriding
    `objectAtContent`:
  
    ```javascript
    let pets = ['dog', 'cat', 'fish'];
    let ap = Ember.ArrayProxy.create({
        content: Ember.A(pets),
        objectAtContent: function(idx) {
            return this.get('content').objectAt(idx).toUpperCase();
        }
    });
  
    ap.get('firstObject'); // . 'DOG'
    ```
  
    @class ArrayProxy
    @namespace Ember
    @extends Ember.Object
    @uses Ember.MutableArray
    @public
  */
  exports.default = _object.default.extend(_mutable_array.default, {

    /**
      The content array. Must be an object that implements `Ember.Array` and/or
      `Ember.MutableArray.`
       @property content
      @type Ember.Array
      @private
    */
    content: null,

    /**
     The array that the proxy pretends to be. In the default `ArrayProxy`
     implementation, this and `content` are the same. Subclasses of `ArrayProxy`
     can override this property to provide things like sorting and filtering.
      @property arrangedContent
     @private
    */
    arrangedContent: (0, _emberMetal.alias)('content'),

    /**
      Should actually retrieve the object at the specified index from the
      content. You can override this method in subclasses to transform the
      content item to something new.
       This method will only be called if content is non-`null`.
       @method objectAtContent
      @param {Number} idx The index to retrieve.
      @return {Object} the value or undefined if none found
      @public
    */
    objectAtContent: function (idx) {
      return (0, _array.objectAt)((0, _emberMetal.get)(this, 'arrangedContent'), idx);
    },


    /**
      Should actually replace the specified objects on the content array.
      You can override this method in subclasses to transform the content item
      into something new.
       This method will only be called if content is non-`null`.
       @method replaceContent
      @param {Number} idx The starting index
      @param {Number} amt The number of items to remove from the content.
      @param {Array} objects Optional array of objects to insert or null if no
        objects.
      @return {void}
      @private
    */
    replaceContent: function (idx, amt, objects) {
      (0, _emberMetal.get)(this, 'content').replace(idx, amt, objects);
    },


    /**
      Invoked when the content property is about to change. Notifies observers that the
      entire array content will change.
       @private
      @method _contentWillChange
    */
    _contentWillChange: (0, _emberMetal._beforeObserver)('content', function () {
      this._teardownContent();
    }),

    _teardownContent: function () {
      var content = (0, _emberMetal.get)(this, 'content');

      if (content) {
        (0, _array.removeArrayObserver)(content, this, {
          willChange: 'contentArrayWillChange',
          didChange: 'contentArrayDidChange'
        });
      }
    },


    /**
      Override to implement content array `willChange` observer.
       @method contentArrayWillChange
       @param {Ember.Array} contentArray the content array
      @param {Number} start starting index of the change
      @param {Number} removeCount count of items removed
      @param {Number} addCount count of items added
      @private
    */
    contentArrayWillChange: K,
    /**
      Override to implement content array `didChange` observer.
       @method contentArrayDidChange
       @param {Ember.Array} contentArray the content array
      @param {Number} start starting index of the change
      @param {Number} removeCount count of items removed
      @param {Number} addCount count of items added
      @private
    */
    contentArrayDidChange: K,

    /**
      Invoked when the content property changes. Notifies observers that the
      entire array content has changed.
       @private
      @method _contentDidChange
    */
    _contentDidChange: (0, _emberMetal.observer)('content', function () {
      var content = (0, _emberMetal.get)(this, 'content');

      (true && !(content !== this) && (0, _emberDebug.assert)('Can\'t set ArrayProxy\'s content to itself', content !== this));


      this._setupContent();
    }),

    _setupContent: function () {
      var content = (0, _emberMetal.get)(this, 'content');

      if (content) {
        (true && !((0, _utils.isArray)(content) || content.isDestroyed) && (0, _emberDebug.assert)('ArrayProxy expects an Array or Ember.ArrayProxy, but you passed ' + typeof content, (0, _utils.isArray)(content) || content.isDestroyed));


        (0, _array.addArrayObserver)(content, this, {
          willChange: 'contentArrayWillChange',
          didChange: 'contentArrayDidChange'
        });
      }
    },


    _arrangedContentWillChange: (0, _emberMetal._beforeObserver)('arrangedContent', function () {
      var arrangedContent = (0, _emberMetal.get)(this, 'arrangedContent');
      var len = arrangedContent ? (0, _emberMetal.get)(arrangedContent, 'length') : 0;

      this.arrangedContentArrayWillChange(this, 0, len, undefined);
      this.arrangedContentWillChange(this);

      this._teardownArrangedContent(arrangedContent);
    }),

    _arrangedContentDidChange: (0, _emberMetal.observer)('arrangedContent', function () {
      var arrangedContent = (0, _emberMetal.get)(this, 'arrangedContent');
      var len = arrangedContent ? (0, _emberMetal.get)(arrangedContent, 'length') : 0;

      (true && !(arrangedContent !== this) && (0, _emberDebug.assert)('Can\'t set ArrayProxy\'s content to itself', arrangedContent !== this));


      this._setupArrangedContent();

      this.arrangedContentDidChange(this);
      this.arrangedContentArrayDidChange(this, 0, undefined, len);
    }),

    _setupArrangedContent: function () {
      var arrangedContent = (0, _emberMetal.get)(this, 'arrangedContent');

      if (arrangedContent) {
        (true && !((0, _utils.isArray)(arrangedContent) || arrangedContent.isDestroyed) && (0, _emberDebug.assert)('ArrayProxy expects an Array or Ember.ArrayProxy, but you passed ' + typeof arrangedContent, (0, _utils.isArray)(arrangedContent) || arrangedContent.isDestroyed));


        (0, _array.addArrayObserver)(arrangedContent, this, {
          willChange: 'arrangedContentArrayWillChange',
          didChange: 'arrangedContentArrayDidChange'
        });
      }
    },
    _teardownArrangedContent: function () {
      var arrangedContent = (0, _emberMetal.get)(this, 'arrangedContent');

      if (arrangedContent) {
        (0, _array.removeArrayObserver)(arrangedContent, this, {
          willChange: 'arrangedContentArrayWillChange',
          didChange: 'arrangedContentArrayDidChange'
        });
      }
    },


    arrangedContentWillChange: K,
    arrangedContentDidChange: K,

    objectAt: function (idx) {
      return (0, _emberMetal.get)(this, 'content') && this.objectAtContent(idx);
    },


    length: (0, _emberMetal.computed)(function () {
      var arrangedContent = (0, _emberMetal.get)(this, 'arrangedContent');
      return arrangedContent ? (0, _emberMetal.get)(arrangedContent, 'length') : 0;
      // No dependencies since Enumerable notifies length of change
    }),

    _replace: function (idx, amt, objects) {
      var content = (0, _emberMetal.get)(this, 'content');
      (true && !(content) && (0, _emberDebug.assert)('The content property of ' + this.constructor + ' should be set before modifying it', content));

      if (content) {
        this.replaceContent(idx, amt, objects);
      }

      return this;
    },
    replace: function () {
      if ((0, _emberMetal.get)(this, 'arrangedContent') === (0, _emberMetal.get)(this, 'content')) {
        this._replace.apply(this, arguments);
      } else {
        throw new _emberDebug.Error('Using replace on an arranged ArrayProxy is not allowed.');
      }
    },
    _insertAt: function (idx, object) {
      if (idx > (0, _emberMetal.get)(this, 'content.length')) {
        throw new _emberDebug.Error(OUT_OF_RANGE_EXCEPTION);
      }

      this._replace(idx, 0, [object]);
      return this;
    },
    insertAt: function (idx, object) {
      if ((0, _emberMetal.get)(this, 'arrangedContent') === (0, _emberMetal.get)(this, 'content')) {
        return this._insertAt(idx, object);
      } else {
        throw new _emberDebug.Error('Using insertAt on an arranged ArrayProxy is not allowed.');
      }
    },
    removeAt: function (start, len) {
      if ('number' === typeof start) {
        var content = (0, _emberMetal.get)(this, 'content');
        var arrangedContent = (0, _emberMetal.get)(this, 'arrangedContent');
        var indices = [];

        if (start < 0 || start >= (0, _emberMetal.get)(this, 'length')) {
          throw new _emberDebug.Error(OUT_OF_RANGE_EXCEPTION);
        }

        if (len === undefined) {
          len = 1;
        }

        // Get a list of indices in original content to remove
        for (var i = start; i < start + len; i++) {
          // Use arrangedContent here so we avoid confusion with objects transformed by objectAtContent
          indices.push(content.indexOf((0, _array.objectAt)(arrangedContent, i)));
        }

        // Replace in reverse order since indices will change
        indices.sort(function (a, b) {
          return b - a;
        });

        (0, _emberMetal.beginPropertyChanges)();
        for (var _i = 0; _i < indices.length; _i++) {
          this._replace(indices[_i], 1, EMPTY);
        }
        (0, _emberMetal.endPropertyChanges)();
      }

      return this;
    },
    pushObject: function (obj) {
      this._insertAt((0, _emberMetal.get)(this, 'content.length'), obj);
      return obj;
    },
    pushObjects: function (objects) {
      if (!(_enumerable.default.detect(objects) || (0, _utils.isArray)(objects))) {
        throw new TypeError('Must pass Ember.Enumerable to Ember.MutableArray#pushObjects');
      }
      this._replace((0, _emberMetal.get)(this, 'length'), 0, objects);
      return this;
    },
    setObjects: function (objects) {
      if (objects.length === 0) {
        return this.clear();
      }

      var len = (0, _emberMetal.get)(this, 'length');
      this._replace(0, len, objects);
      return this;
    },
    unshiftObject: function (obj) {
      this._insertAt(0, obj);
      return obj;
    },
    unshiftObjects: function (objects) {
      this._replace(0, 0, objects);
      return this;
    },
    slice: function () {
      var arr = this.toArray();
      return arr.slice.apply(arr, arguments);
    },
    arrangedContentArrayWillChange: function (item, idx, removedCnt, addedCnt) {
      this.arrayContentWillChange(idx, removedCnt, addedCnt);
    },
    arrangedContentArrayDidChange: function (item, idx, removedCnt, addedCnt) {
      this.arrayContentDidChange(idx, removedCnt, addedCnt);
    },
    init: function () {
      this._super.apply(this, arguments);
      this._setupContent();
      this._setupArrangedContent();
    },
    willDestroy: function () {
      this._teardownArrangedContent();
      this._teardownContent();
    }
  });
});