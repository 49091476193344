enifed('ember-runtime/controllers/controller', ['exports', 'ember-debug', 'ember-runtime/system/object', 'ember-runtime/mixins/controller', 'ember-runtime/inject', 'ember-runtime/mixins/action_handler'], function (exports, _emberDebug, _object, _controller, _inject, _action_handler) {
  'use strict';

  /**
  @module ember
  @submodule ember-runtime
  */

  /**
    @class Controller
    @namespace Ember
    @extends Ember.Object
    @uses Ember.ControllerMixin
    @public
  */
  var Controller = _object.default.extend(_controller.default);

  (0, _action_handler.deprecateUnderscoreActions)(Controller);

  function controllerInjectionHelper(factory) {
    (true && !(_controller.default.detect(factory.PrototypeMixin)) && (0, _emberDebug.assert)('Defining an injected controller property on a ' + 'non-controller is not allowed.', _controller.default.detect(factory.PrototypeMixin)));
  }

  /**
    Creates a property that lazily looks up another controller in the container.
    Can only be used when defining another controller.
  
    Example:
  
    ```javascript
    App.PostController = Ember.Controller.extend({
      posts: Ember.inject.controller()
    });
    ```
  
    This example will create a `posts` property on the `post` controller that
    looks up the `posts` controller in the container, making it easy to
    reference other controllers. This is functionally equivalent to:
  
    ```javascript
    App.PostController = Ember.Controller.extend({
      needs: 'posts',
      posts: Ember.computed.alias('controllers.posts')
    });
    ```
  
    @method controller
    @since 1.10.0
    @for Ember.inject
    @param {String} name (optional) name of the controller to inject, defaults
           to the property's name
    @return {Ember.InjectedProperty} injection descriptor instance
    @public
  */
  (0, _inject.createInjectionHelper)('controller', controllerInjectionHelper);

  exports.default = Controller;
});