enifed('ember-routing/system/router_state', ['exports', 'ember-utils', 'ember-metal', 'ember-runtime'], function (exports, _emberUtils, _emberMetal, _emberRuntime) {
  'use strict';

  exports.default = _emberRuntime.Object.extend({
    emberRouter: null,
    routerJs: null,
    routerJsState: null,

    isActiveIntent: function (routeName, models, queryParams, queryParamsMustMatch) {
      var state = this.routerJsState;
      if (!this.routerJs.isActiveIntent(routeName, models, null, state)) {
        return false;
      }

      var emptyQueryParams = (0, _emberMetal.isEmpty)(Object.keys(queryParams));

      if (queryParamsMustMatch && !emptyQueryParams) {
        var visibleQueryParams = {};
        (0, _emberUtils.assign)(visibleQueryParams, queryParams);

        this.emberRouter._prepareQueryParams(routeName, models, visibleQueryParams);
        return shallowEqual(visibleQueryParams, state.queryParams);
      }

      return true;
    }
  });


  function shallowEqual(a, b) {
    var k = void 0;
    for (k in a) {
      if (a.hasOwnProperty(k) && a[k] !== b[k]) {
        return false;
      }
    }
    for (k in b) {
      if (b.hasOwnProperty(k) && a[k] !== b[k]) {
        return false;
      }
    }
    return true;
  }
});