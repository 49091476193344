enifed('ember-views/system/ext', ['ember-metal'], function (_emberMetal) {
  'use strict';

  // Add a new named queue for rendering views that happens
  // after bindings have synced, and a queue for scheduling actions
  // that should occur after view rendering.
  _emberMetal.run._addQueue('render', 'actions'); /**
                                                  @module ember
                                                  @submodule ember-views
                                                  */

  _emberMetal.run._addQueue('afterRender', 'render');
});