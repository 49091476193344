enifed("ember-testing/helpers/and_then", ["exports"], function (exports) {
  "use strict";

  exports.default = andThen;
  /**
  @module ember
  @submodule ember-testing
  */
  function andThen(app, callback) {
    return app.testHelpers.wait(callback(app));
  }
});