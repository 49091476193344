enifed('ember-runtime/system/object', ['exports', 'ember-utils', 'ember-metal', 'ember-runtime/system/core_object', 'ember-runtime/mixins/observable', 'ember-debug'], function (exports, _emberUtils, _emberMetal, _core_object, _observable, _emberDebug) {
  'use strict';

  exports.FrameworkObject = undefined;

  var _CoreObject$extend;

  var OVERRIDE_CONTAINER_KEY = (0, _emberUtils.symbol)('OVERRIDE_CONTAINER_KEY');
  var OVERRIDE_OWNER = (0, _emberUtils.symbol)('OVERRIDE_OWNER');

  /**
    `Ember.Object` is the main base class for all Ember objects. It is a subclass
    of `Ember.CoreObject` with the `Ember.Observable` mixin applied. For details,
    see the documentation for each of these.
  
    @class Object
    @namespace Ember
    @extends Ember.CoreObject
    @uses Ember.Observable
    @public
  */
  var EmberObject = _core_object.default.extend(_observable.default, (_CoreObject$extend = {
    _debugContainerKey: (0, _emberMetal.descriptor)({
      enumerable: false,
      get: function () {
        if (this[OVERRIDE_CONTAINER_KEY]) {
          return this[OVERRIDE_CONTAINER_KEY];
        }

        var meta = (0, _emberMetal.meta)(this);
        var factory = meta.factory;


        return factory && factory.fullName;
      }
    })

  }, _CoreObject$extend[_emberUtils.OWNER] = (0, _emberMetal.descriptor)({
    enumerable: false,
    get: function () {
      if (this[OVERRIDE_OWNER]) {
        return this[OVERRIDE_OWNER];
      }

      var meta = (0, _emberMetal.meta)(this);
      var factory = meta.factory;


      return factory && factory.owner;
    },
    set: function (value) {
      this[OVERRIDE_OWNER] = value;
    }
  }), _CoreObject$extend));

  EmberObject.toString = function () {
    return 'Ember.Object';
  };

  var FrameworkObject = exports.FrameworkObject = EmberObject;

  if (true) {
    var _EmberObject$extend;

    var INIT_WAS_CALLED = (0, _emberUtils.symbol)('INIT_WAS_CALLED');
    var ASSERT_INIT_WAS_CALLED = (0, _emberUtils.symbol)('ASSERT_INIT_WAS_CALLED');

    exports.FrameworkObject = FrameworkObject = EmberObject.extend((_EmberObject$extend = {
      init: function () {
        this._super.apply(this, arguments);
        this[INIT_WAS_CALLED] = true;
      }
    }, _EmberObject$extend[ASSERT_INIT_WAS_CALLED] = (0, _emberMetal.on)('init', function () {
      (true && !(this[INIT_WAS_CALLED]) && (0, _emberDebug.assert)('You must call `this._super(...arguments);` when overriding `init` on a framework object. Please update ' + this + ' to call `this._super(...arguments);` from `init`.', this[INIT_WAS_CALLED]));
    }), _EmberObject$extend));
  }

  exports.default = EmberObject;
});