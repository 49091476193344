enifed('ember-testing/helpers/click', ['exports', 'ember-testing/events'], function (exports, _events) {
  'use strict';

  exports.default = click;


  /**
    Clicks an element and triggers any actions triggered by the element's `click`
    event.
  
    Example:
  
    ```javascript
    click('.some-jQuery-selector').then(function() {
      // assert something
    });
    ```
  
    @method click
    @param {String} selector jQuery selector for finding element on the DOM
    @param {Object} context A DOM Element, Document, or jQuery to use as context
    @return {RSVP.Promise<undefined>}
    @public
  */
  function click(app, selector, context) {
    var $el = app.testHelpers.findWithAssert(selector, context);
    var el = $el[0];

    (0, _events.fireEvent)(el, 'mousedown');

    (0, _events.focus)(el);

    (0, _events.fireEvent)(el, 'mouseup');
    (0, _events.fireEvent)(el, 'click');

    return app.testHelpers.wait();
  } /**
    @module ember
    @submodule ember-testing
    */
});