enifed("ember-debug/error", ["exports", "ember-babel"], function (exports, _emberBabel) {
  "use strict";

  function ExtendBuiltin(klass) {
    function ExtendableBuiltin() {
      klass.apply(this, arguments);
    }

    ExtendableBuiltin.prototype = Object.create(klass.prototype);
    ExtendableBuiltin.prototype.constructor = ExtendableBuiltin;
    return ExtendableBuiltin;
  }

  /**
    A subclass of the JavaScript Error object for use in Ember.
  
    @class Error
    @namespace Ember
    @extends Error
    @constructor
    @public
  */

  var EmberError = function (_ExtendBuiltin) {
    (0, _emberBabel.inherits)(EmberError, _ExtendBuiltin);

    function EmberError(message) {
      (0, _emberBabel.classCallCheck)(this, EmberError);

      var _this = (0, _emberBabel.possibleConstructorReturn)(this, _ExtendBuiltin.call(this));

      if (!(_this instanceof EmberError)) {
        var _ret;

        return _ret = new EmberError(message), (0, _emberBabel.possibleConstructorReturn)(_this, _ret);
      }

      var error = Error.call(_this, message);
      _this.stack = error.stack;
      _this.description = error.description;
      _this.fileName = error.fileName;
      _this.lineNumber = error.lineNumber;
      _this.message = error.message;
      _this.name = error.name;
      _this.number = error.number;
      _this.code = error.code;
      return _this;
    }

    return EmberError;
  }(ExtendBuiltin(Error));

  exports.default = EmberError;
});