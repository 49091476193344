enifed('ember-runtime/system/lazy_load', ['exports', 'ember-environment'], function (exports, _emberEnvironment) {
  'use strict';

  exports._loaded = undefined;
  exports.onLoad = onLoad;
  exports.runLoadHooks = runLoadHooks;


  /**
    @module ember
    @submodule ember-runtime
  */

  var loadHooks = _emberEnvironment.ENV.EMBER_LOAD_HOOKS || {}; /*globals CustomEvent */

  var loaded = {};
  var _loaded = exports._loaded = loaded;

  /**
    Detects when a specific package of Ember (e.g. 'Ember.Application')
    has fully loaded and is available for extension.
  
    The provided `callback` will be called with the `name` passed
    resolved from a string into the object:
  
    ``` javascript
    Ember.onLoad('Ember.Application' function(hbars) {
      hbars.registerHelper(...);
    });
    ```
  
    @method onLoad
    @for Ember
    @param name {String} name of hook
    @param callback {Function} callback to be called
    @private
  */
  function onLoad(name, callback) {
    var object = loaded[name];

    loadHooks[name] = loadHooks[name] || [];
    loadHooks[name].push(callback);

    if (object) {
      callback(object);
    }
  }

  /**
    Called when an Ember.js package (e.g Ember.Application) has finished
    loading. Triggers any callbacks registered for this event.
  
    @method runLoadHooks
    @for Ember
    @param name {String} name of hook
    @param object {Object} object to pass to callbacks
    @private
  */
  function runLoadHooks(name, object) {
    loaded[name] = object;
    var window = _emberEnvironment.environment.window;

    if (window && typeof CustomEvent === 'function') {
      var event = new CustomEvent(name, { detail: object, name: name });
      window.dispatchEvent(event);
    }

    if (loadHooks[name]) {
      loadHooks[name].forEach(function (callback) {
        return callback(object);
      });
    }
  }
});