define('ember-cli-nouislider/components/range-slider', ['exports', 'noUiSlider'], function (exports, _noUiSlider) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var warn = Ember.Logger.warn;
  exports.default = Ember.Component.extend({
    attributeBindings: ['disabledOrUndefined:disabled'],
    slider: null,
    start: undefined,
    step: undefined,
    margin: undefined,
    limit: undefined,
    pips: undefined,
    animate: true,
    snap: false,
    connect: false,
    disabled: false,
    orientation: 'horizontal',
    direction: 'ltr',
    behaviour: 'tap',
    tooltips: false,
    multitouch: false,

    min: 0,
    max: 100,

    range: Ember.computed('min', 'max', function () {
      return {
        min: this.get('min'),
        max: this.get('max')
      };
    }),

    formatTo: function formatTo(value) {
      return value;
    },
    formatFrom: function formatFrom(value) {
      return +value;
    },


    format: Ember.computed('formatTo', 'formatFrom', function () {
      return {
        to: this.get('formatTo'),
        from: this.get('formatFrom')
      };
    }),

    didInsertElement: function didInsertElement() {
      this.setup();
    },
    setup: function setup() {
      var _this = this;

      var element = this.get('element');
      var slider = element.noUiSlider;

      var properties = this.getProperties('start', 'step', 'margin', 'limit', 'range', 'connect', 'orientation', 'direction', 'behaviour', 'animate', 'snap', 'pips', 'format', 'tooltips', 'multitouch', 'cssPrefix', 'cssClasses');
      var sliderEvents = Ember.A(['change', 'set', 'slide', 'update', 'start', 'end']);

      // We first check if the element has a slider already created
      if (slider && slider.destroy) {
        slider.destroy();
      }

      try {
        slider = _noUiSlider.default.create(element, properties, true);
      } catch (err) {
        warn('[ember-cli-nouislider]: ' + err);
      }

      this.slider = slider;

      sliderEvents.forEach(function (event) {
        if (!Ember.isEmpty(_this.get('on-' + event))) {
          slider.on(event, function () {
            Ember.run(_this, function () {
              var val = this.get("slider").get();
              this.sendAction('on-' + event, val);
            });
          });
        }
      });
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this.update();
    },
    update: function update() {
      var slider = this.slider;

      var properties = this.getProperties('margin', 'limit', 'step', 'range', 'animate', 'snap', 'start');

      if (slider) {
        slider.updateOptions(properties);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.teardown();
    },
    teardown: function teardown() {
      var slider = this.slider;

      // Remove all event listeners

      slider.off();

      slider.destroy();
    },


    setValue: Ember.observer('start', function () {
      var slider = this.slider;


      if (slider) {
        var value = this.get('start');
        slider.set(value);
      }
    }),

    // disabled can't be just `false` - this leads to an attribute of disabled="false"
    disabledOrUndefined: Ember.computed('disabled', function () {
      if (this.get('disabled')) {
        return true;
      }
      return undefined;
    })
  });
});