enifed('ember-glimmer/helpers/log', ['exports', 'ember-glimmer/utils/references', 'ember-console'], function (exports, _references, _emberConsole) {
  'use strict';

  exports.default = function (vm, args) {
    return new _references.InternalHelperReference(log, args.capture());
  };

  /**
    `log` allows you to output the value of variables in the current rendering
    context. `log` also accepts primitive types such as strings or numbers.
  
    ```handlebars
    {{log "myVariable:" myVariable }}
    ```
  
    @method log
    @for Ember.Templates.helpers
    @param {Array} params
    @public
  */
  function log(_ref) {
    var positional = _ref.positional;

    _emberConsole.default.log.apply(null, positional.value());
  }
  /**
  @module ember
  @submodule ember-glimmer
  */
});