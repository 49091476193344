enifed('ember-glimmer/utils/references', ['exports', '@glimmer/runtime', 'ember-babel', 'ember-utils', 'ember-metal', '@glimmer/reference', 'ember-glimmer/utils/to-bool', 'ember-glimmer/helper', 'ember/features'], function (exports, _runtime, _emberBabel, _emberUtils, _emberMetal, _reference, _toBool, _helper, _features) {
  'use strict';

  exports.UnboundReference = exports.InternalHelperReference = exports.ClassBasedHelperReference = exports.SimpleHelperReference = exports.ConditionalReference = exports.UpdatablePrimitiveReference = exports.UpdatableReference = exports.NestedPropertyReference = exports.RootPropertyReference = exports.PropertyReference = exports.RootReference = exports.CachedReference = exports.UNDEFINED_REFERENCE = exports.NULL_REFERENCE = exports.UPDATE = undefined;
  Object.defineProperty(exports, 'NULL_REFERENCE', {
    enumerable: true,
    get: function () {
      return _runtime.NULL_REFERENCE;
    }
  });
  Object.defineProperty(exports, 'UNDEFINED_REFERENCE', {
    enumerable: true,
    get: function () {
      return _runtime.UNDEFINED_REFERENCE;
    }
  });
  var UPDATE = exports.UPDATE = (0, _emberUtils.symbol)('UPDATE');

  var maybeFreeze = void 0;
  if (true) {
    // gaurding this in a DEBUG gaurd (as well as all invocations)
    // so that it is properly stripped during the minification's
    // dead code elimination
    maybeFreeze = function (obj) {
      // re-freezing an already frozen object introduces a significant
      // performance penalty on Chrome (tested through 59).
      //
      // See: https://bugs.chromium.org/p/v8/issues/detail?id=6450
      if (!Object.isFrozen(obj) && _emberUtils.HAS_NATIVE_WEAKMAP) {
        Object.freeze(obj);
      }
    };
  }

  // @abstract
  // @implements PathReference

  var EmberPathReference = function () {
    function EmberPathReference() {
      (0, _emberBabel.classCallCheck)(this, EmberPathReference);
    }

    EmberPathReference.prototype.get = function get(key) {
      return PropertyReference.create(this, key);
    };

    return EmberPathReference;
  }();

  var CachedReference = exports.CachedReference = function (_EmberPathReference) {
    (0, _emberBabel.inherits)(CachedReference, _EmberPathReference);

    function CachedReference() {
      (0, _emberBabel.classCallCheck)(this, CachedReference);

      var _this = (0, _emberBabel.possibleConstructorReturn)(this, _EmberPathReference.call(this));

      _this._lastRevision = null;
      _this._lastValue = null;
      return _this;
    }

    CachedReference.prototype.value = function value() {
      var tag = this.tag,
          _lastRevision = this._lastRevision,
          _lastValue = this._lastValue;


      if (!_lastRevision || !tag.validate(_lastRevision)) {
        _lastValue = this._lastValue = this.compute();
        this._lastRevision = tag.value();
      }

      return _lastValue;
    };

    // @abstract compute()


    return CachedReference;
  }(EmberPathReference);

  var RootReference = exports.RootReference = function (_ConstReference) {
    (0, _emberBabel.inherits)(RootReference, _ConstReference);

    function RootReference(value) {
      (0, _emberBabel.classCallCheck)(this, RootReference);

      var _this2 = (0, _emberBabel.possibleConstructorReturn)(this, _ConstReference.call(this, value));

      _this2.children = Object.create(null);
      return _this2;
    }

    RootReference.prototype.get = function get(propertyKey) {
      var ref = this.children[propertyKey];

      if (!ref) {
        ref = this.children[propertyKey] = new RootPropertyReference(this.inner, propertyKey);
      }

      return ref;
    };

    return RootReference;
  }(_reference.ConstReference);

  var TwoWayFlushDetectionTag = void 0;

  if (_features.EMBER_GLIMMER_DETECT_BACKTRACKING_RERENDER || _features.EMBER_GLIMMER_ALLOW_BACKTRACKING_RERENDER) {
    TwoWayFlushDetectionTag = function () {
      function TwoWayFlushDetectionTag(tag, key, ref) {
        (0, _emberBabel.classCallCheck)(this, TwoWayFlushDetectionTag);

        this.tag = tag;
        this.parent = null;
        this.key = key;
        this.ref = ref;
      }

      TwoWayFlushDetectionTag.prototype.value = function value() {
        return this.tag.value();
      };

      TwoWayFlushDetectionTag.prototype.validate = function validate(ticket) {
        var parent = this.parent,
            key = this.key;


        var isValid = this.tag.validate(ticket);

        if (isValid && parent) {
          (0, _emberMetal.didRender)(parent, key, this.ref);
        }

        return isValid;
      };

      TwoWayFlushDetectionTag.prototype.didCompute = function didCompute(parent) {
        this.parent = parent;
        (0, _emberMetal.didRender)(parent, this.key, this.ref);
      };

      return TwoWayFlushDetectionTag;
    }();
  }

  var PropertyReference = exports.PropertyReference = function (_CachedReference) {
    (0, _emberBabel.inherits)(PropertyReference, _CachedReference);

    function PropertyReference() {
      (0, _emberBabel.classCallCheck)(this, PropertyReference);
      return (0, _emberBabel.possibleConstructorReturn)(this, _CachedReference.apply(this, arguments));
    }

    PropertyReference.create = function create(parentReference, propertyKey) {
      if ((0, _reference.isConst)(parentReference)) {
        return new RootPropertyReference(parentReference.value(), propertyKey);
      } else {
        return new NestedPropertyReference(parentReference, propertyKey);
      }
    };

    PropertyReference.prototype.get = function get(key) {
      return new NestedPropertyReference(this, key);
    };

    return PropertyReference;
  }(CachedReference);

  var RootPropertyReference = exports.RootPropertyReference = function (_PropertyReference) {
    (0, _emberBabel.inherits)(RootPropertyReference, _PropertyReference);

    function RootPropertyReference(parentValue, propertyKey) {
      (0, _emberBabel.classCallCheck)(this, RootPropertyReference);

      var _this4 = (0, _emberBabel.possibleConstructorReturn)(this, _PropertyReference.call(this));

      _this4._parentValue = parentValue;
      _this4._propertyKey = propertyKey;

      if (_features.EMBER_GLIMMER_DETECT_BACKTRACKING_RERENDER || _features.EMBER_GLIMMER_ALLOW_BACKTRACKING_RERENDER) {
        _this4.tag = new TwoWayFlushDetectionTag((0, _emberMetal.tagForProperty)(parentValue, propertyKey), propertyKey, _this4);
      } else {
        _this4.tag = (0, _emberMetal.tagForProperty)(parentValue, propertyKey);
      }

      if (_features.MANDATORY_SETTER) {
        (0, _emberMetal.watchKey)(parentValue, propertyKey);
      }
      return _this4;
    }

    RootPropertyReference.prototype.compute = function compute() {
      var _parentValue = this._parentValue,
          _propertyKey = this._propertyKey;


      if (_features.EMBER_GLIMMER_DETECT_BACKTRACKING_RERENDER || _features.EMBER_GLIMMER_ALLOW_BACKTRACKING_RERENDER) {
        this.tag.didCompute(_parentValue);
      }

      return (0, _emberMetal.get)(_parentValue, _propertyKey);
    };

    RootPropertyReference.prototype[UPDATE] = function (value) {
      (0, _emberMetal.set)(this._parentValue, this._propertyKey, value);
    };

    return RootPropertyReference;
  }(PropertyReference);

  var NestedPropertyReference = exports.NestedPropertyReference = function (_PropertyReference2) {
    (0, _emberBabel.inherits)(NestedPropertyReference, _PropertyReference2);

    function NestedPropertyReference(parentReference, propertyKey) {
      (0, _emberBabel.classCallCheck)(this, NestedPropertyReference);

      var _this5 = (0, _emberBabel.possibleConstructorReturn)(this, _PropertyReference2.call(this));

      var parentReferenceTag = parentReference.tag;
      var parentObjectTag = new _reference.UpdatableTag(_reference.CONSTANT_TAG);

      _this5._parentReference = parentReference;
      _this5._parentObjectTag = parentObjectTag;
      _this5._propertyKey = propertyKey;

      if (_features.EMBER_GLIMMER_DETECT_BACKTRACKING_RERENDER || _features.EMBER_GLIMMER_ALLOW_BACKTRACKING_RERENDER) {
        var tag = (0, _reference.combine)([parentReferenceTag, parentObjectTag]);
        _this5.tag = new TwoWayFlushDetectionTag(tag, propertyKey, _this5);
      } else {
        _this5.tag = (0, _reference.combine)([parentReferenceTag, parentObjectTag]);
      }
      return _this5;
    }

    NestedPropertyReference.prototype.compute = function compute() {
      var _parentReference = this._parentReference,
          _parentObjectTag = this._parentObjectTag,
          _propertyKey = this._propertyKey;


      var parentValue = _parentReference.value();

      _parentObjectTag.update((0, _emberMetal.tagForProperty)(parentValue, _propertyKey));

      var parentValueType = typeof parentValue;

      if (parentValueType === 'string' && _propertyKey === 'length') {
        return parentValue.length;
      }

      if (parentValueType === 'object' && parentValue !== null || parentValueType === 'function') {
        if (_features.MANDATORY_SETTER) {
          (0, _emberMetal.watchKey)(parentValue, _propertyKey);
        }

        if (_features.EMBER_GLIMMER_DETECT_BACKTRACKING_RERENDER || _features.EMBER_GLIMMER_ALLOW_BACKTRACKING_RERENDER) {
          this.tag.didCompute(parentValue);
        }

        return (0, _emberMetal.get)(parentValue, _propertyKey);
      } else {
        return undefined;
      }
    };

    NestedPropertyReference.prototype[UPDATE] = function (value) {
      var parent = this._parentReference.value();
      (0, _emberMetal.set)(parent, this._propertyKey, value);
    };

    return NestedPropertyReference;
  }(PropertyReference);

  var UpdatableReference = exports.UpdatableReference = function (_EmberPathReference2) {
    (0, _emberBabel.inherits)(UpdatableReference, _EmberPathReference2);

    function UpdatableReference(value) {
      (0, _emberBabel.classCallCheck)(this, UpdatableReference);

      var _this6 = (0, _emberBabel.possibleConstructorReturn)(this, _EmberPathReference2.call(this));

      _this6.tag = new _reference.DirtyableTag();
      _this6._value = value;
      return _this6;
    }

    UpdatableReference.prototype.value = function value() {
      return this._value;
    };

    UpdatableReference.prototype.update = function update(value) {
      var _value = this._value;


      if (value !== _value) {
        this.tag.dirty();
        this._value = value;
      }
    };

    return UpdatableReference;
  }(EmberPathReference);

  var UpdatablePrimitiveReference = exports.UpdatablePrimitiveReference = function (_UpdatableReference) {
    (0, _emberBabel.inherits)(UpdatablePrimitiveReference, _UpdatableReference);

    function UpdatablePrimitiveReference() {
      (0, _emberBabel.classCallCheck)(this, UpdatablePrimitiveReference);
      return (0, _emberBabel.possibleConstructorReturn)(this, _UpdatableReference.apply(this, arguments));
    }

    UpdatablePrimitiveReference.prototype.get = function get() {
      return _runtime.UNDEFINED_REFERENCE;
    };

    return UpdatablePrimitiveReference;
  }(UpdatableReference);

  var ConditionalReference = exports.ConditionalReference = function (_GlimmerConditionalRe) {
    (0, _emberBabel.inherits)(ConditionalReference, _GlimmerConditionalRe);

    ConditionalReference.create = function create(reference) {
      if ((0, _reference.isConst)(reference)) {
        var value = reference.value();

        if ((0, _emberMetal.isProxy)(value)) {
          return new RootPropertyReference(value, 'isTruthy');
        } else {
          return _runtime.PrimitiveReference.create((0, _toBool.default)(value));
        }
      }

      return new ConditionalReference(reference);
    };

    function ConditionalReference(reference) {
      (0, _emberBabel.classCallCheck)(this, ConditionalReference);

      var _this8 = (0, _emberBabel.possibleConstructorReturn)(this, _GlimmerConditionalRe.call(this, reference));

      _this8.objectTag = new _reference.UpdatableTag(_reference.CONSTANT_TAG);
      _this8.tag = (0, _reference.combine)([reference.tag, _this8.objectTag]);
      return _this8;
    }

    ConditionalReference.prototype.toBool = function toBool(predicate) {
      if ((0, _emberMetal.isProxy)(predicate)) {
        this.objectTag.update((0, _emberMetal.tagForProperty)(predicate, 'isTruthy'));
        return (0, _emberMetal.get)(predicate, 'isTruthy');
      } else {
        this.objectTag.update((0, _emberMetal.tagFor)(predicate));
        return (0, _toBool.default)(predicate);
      }
    };

    return ConditionalReference;
  }(_runtime.ConditionalReference);

  var SimpleHelperReference = exports.SimpleHelperReference = function (_CachedReference2) {
    (0, _emberBabel.inherits)(SimpleHelperReference, _CachedReference2);

    SimpleHelperReference.create = function create(helper, args) {
      if ((0, _reference.isConst)(args)) {
        var positional = args.positional,
            named = args.named;


        var positionalValue = positional.value();
        var namedValue = named.value();

        if (true) {
          maybeFreeze(positionalValue);
          maybeFreeze(namedValue);
        }

        var _result = helper(positionalValue, namedValue);

        if (_result === null) {
          return _runtime.NULL_REFERENCE;
        } else if (_result === undefined) {
          return _runtime.UNDEFINED_REFERENCE;
        } else if (typeof _result === 'object' || typeof _result === 'function') {
          return new RootReference(_result);
        } else {
          return _runtime.PrimitiveReference.create(_result);
        }
      } else {
        return new SimpleHelperReference(helper, args);
      }
    };

    function SimpleHelperReference(helper, args) {
      (0, _emberBabel.classCallCheck)(this, SimpleHelperReference);

      var _this9 = (0, _emberBabel.possibleConstructorReturn)(this, _CachedReference2.call(this));

      _this9.tag = args.tag;
      _this9.helper = helper;
      _this9.args = args;
      return _this9;
    }

    SimpleHelperReference.prototype.compute = function compute() {
      var helper = this.helper,
          _args = this.args,
          positional = _args.positional,
          named = _args.named;


      var positionalValue = positional.value();
      var namedValue = named.value();

      if (true) {
        maybeFreeze(positionalValue);
        maybeFreeze(namedValue);
      }

      return helper(positionalValue, namedValue);
    };

    return SimpleHelperReference;
  }(CachedReference);

  var ClassBasedHelperReference = exports.ClassBasedHelperReference = function (_CachedReference3) {
    (0, _emberBabel.inherits)(ClassBasedHelperReference, _CachedReference3);

    ClassBasedHelperReference.create = function create(helperClass, vm, args) {
      var instance = helperClass.create();
      vm.newDestroyable(instance);
      return new ClassBasedHelperReference(instance, args);
    };

    function ClassBasedHelperReference(instance, args) {
      (0, _emberBabel.classCallCheck)(this, ClassBasedHelperReference);

      var _this10 = (0, _emberBabel.possibleConstructorReturn)(this, _CachedReference3.call(this));

      _this10.tag = (0, _reference.combine)([instance[_helper.RECOMPUTE_TAG], args.tag]);
      _this10.instance = instance;
      _this10.args = args;
      return _this10;
    }

    ClassBasedHelperReference.prototype.compute = function compute() {
      var instance = this.instance,
          _args2 = this.args,
          positional = _args2.positional,
          named = _args2.named;


      var positionalValue = positional.value();
      var namedValue = named.value();

      if (true) {
        maybeFreeze(positionalValue);
        maybeFreeze(namedValue);
      }

      return instance.compute(positionalValue, namedValue);
    };

    return ClassBasedHelperReference;
  }(CachedReference);

  var InternalHelperReference = exports.InternalHelperReference = function (_CachedReference4) {
    (0, _emberBabel.inherits)(InternalHelperReference, _CachedReference4);

    function InternalHelperReference(helper, args) {
      (0, _emberBabel.classCallCheck)(this, InternalHelperReference);

      var _this11 = (0, _emberBabel.possibleConstructorReturn)(this, _CachedReference4.call(this));

      _this11.tag = args.tag;
      _this11.helper = helper;
      _this11.args = args;
      return _this11;
    }

    InternalHelperReference.prototype.compute = function compute() {
      var helper = this.helper,
          args = this.args;

      return helper(args);
    };

    return InternalHelperReference;
  }(CachedReference);

  var UnboundReference = exports.UnboundReference = function (_ConstReference2) {
    (0, _emberBabel.inherits)(UnboundReference, _ConstReference2);

    function UnboundReference() {
      (0, _emberBabel.classCallCheck)(this, UnboundReference);
      return (0, _emberBabel.possibleConstructorReturn)(this, _ConstReference2.apply(this, arguments));
    }

    UnboundReference.create = function create(value) {
      if (value === null) {
        return _runtime.NULL_REFERENCE;
      } else if (value === undefined) {
        return _runtime.UNDEFINED_REFERENCE;
      } else if (typeof value === 'object' || typeof result === 'function') {
        return new UnboundReference(value);
      } else {
        return _runtime.PrimitiveReference.create(value);
      }
    };

    UnboundReference.prototype.get = function get(key) {
      return new UnboundReference((0, _emberMetal.get)(this.inner, key));
    };

    return UnboundReference;
  }(_reference.ConstReference);
});