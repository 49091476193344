enifed('ember-glimmer/syntax/mount', ['exports', 'ember-babel', 'ember-debug', 'ember/features', 'ember-glimmer/syntax/utils', 'ember-glimmer/component-managers/mount'], function (exports, _emberBabel, _emberDebug, _features, _utils, _mount) {
  'use strict';

  exports.mountMacro = mountMacro;
  /**
  @module ember
  @submodule ember-glimmer
  */
  function dynamicEngineFor(vm, args, meta) {
    var env = vm.env;
    var nameRef = args.positional.at(0);

    return new DynamicEngineReference({ nameRef: nameRef, env: env, meta: meta });
  }

  /**
    The `{{mount}}` helper lets you embed a routeless engine in a template.
    Mounting an engine will cause an instance to be booted and its `application`
    template to be rendered.
  
    For example, the following template mounts the `ember-chat` engine:
  
    ```handlebars
    {{! application.hbs }}
    {{mount "ember-chat"}}
    ```
  
    Currently, the engine name is the only argument that can be passed to
    `{{mount}}`.
  
    @method mount
    @for Ember.Templates.helpers
    @category ember-application-engines
    @public
  */
  function mountMacro(name, params, hash, builder) {
    if (_features.EMBER_ENGINES_MOUNT_PARAMS) {
      (true && !(params.length === 1) && (0, _emberDebug.assert)('You can only pass a single positional argument to the {{mount}} helper, e.g. {{mount "chat-engine"}}.', params.length === 1));
    } else {
      (true && !(params.length === 1 && hash === null) && (0, _emberDebug.assert)('You can only pass a single argument to the {{mount}} helper, e.g. {{mount "chat-engine"}}.', params.length === 1 && hash === null));
    }

    var definitionArgs = [params.slice(0, 1), null, null, null];
    var args = [null, (0, _utils.hashToArgs)(hash), null, null];
    builder.component.dynamic(definitionArgs, dynamicEngineFor, args);
    return true;
  }

  var DynamicEngineReference = function () {
    function DynamicEngineReference(_ref) {
      var nameRef = _ref.nameRef,
          env = _ref.env,
          meta = _ref.meta;
      (0, _emberBabel.classCallCheck)(this, DynamicEngineReference);

      this.tag = nameRef.tag;
      this.nameRef = nameRef;
      this.env = env;
      this.meta = meta;
      this._lastName = undefined;
      this._lastDef = undefined;
    }

    DynamicEngineReference.prototype.value = function value() {
      var env = this.env,
          nameRef = this.nameRef;

      var nameOrDef = nameRef.value();

      if (typeof nameOrDef === 'string') {
        if (this._lastName === nameOrDef) {
          return this._lastDef;
        }

        (true && !(env.owner.hasRegistration('engine:' + nameOrDef)) && (0, _emberDebug.assert)('You used `{{mount \'' + nameOrDef + '\'}}`, but the engine \'' + nameOrDef + '\' can not be found.', env.owner.hasRegistration('engine:' + nameOrDef)));


        if (!env.owner.hasRegistration('engine:' + nameOrDef)) {
          return null;
        }

        this._lastName = nameOrDef;
        this._lastDef = new _mount.MountDefinition(nameOrDef);

        return this._lastDef;
      } else {
        (true && !(nameOrDef === null || nameOrDef === undefined) && (0, _emberDebug.assert)('Invalid engine name \'' + nameOrDef + '\' specified, engine name must be either a string, null or undefined.', nameOrDef === null || nameOrDef === undefined));


        return null;
      }
    };

    return DynamicEngineReference;
  }();
});