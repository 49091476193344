enifed('ember-glimmer/component', ['exports', 'ember-utils', 'ember-views', 'ember-runtime', 'ember-debug', 'ember-metal', 'ember-glimmer/utils/references', '@glimmer/reference', '@glimmer/runtime'], function (exports, _emberUtils, _emberViews, _emberRuntime, _emberDebug, _emberMetal, _references, _reference, _runtime) {
  'use strict';

  exports.BOUNDS = exports.HAS_BLOCK = exports.IS_DISPATCHING_ATTRS = exports.ROOT_REF = exports.ARGS = exports.DIRTY_TAG = undefined;

  var _CoreView$extend;

  var DIRTY_TAG = exports.DIRTY_TAG = (0, _emberUtils.symbol)('DIRTY_TAG');
  var ARGS = exports.ARGS = (0, _emberUtils.symbol)('ARGS');
  var ROOT_REF = exports.ROOT_REF = (0, _emberUtils.symbol)('ROOT_REF');
  var IS_DISPATCHING_ATTRS = exports.IS_DISPATCHING_ATTRS = (0, _emberUtils.symbol)('IS_DISPATCHING_ATTRS');
  var HAS_BLOCK = exports.HAS_BLOCK = (0, _emberUtils.symbol)('HAS_BLOCK');
  var BOUNDS = exports.BOUNDS = (0, _emberUtils.symbol)('BOUNDS');

  /**
  @module ember
  @submodule ember-glimmer
  */

  /**
    An `Ember.Component` is a view that is completely
    isolated. Properties accessed in its templates go
    to the view object and actions are targeted at
    the view object. There is no access to the
    surrounding context or outer controller; all
    contextual information must be passed in.
  
    The easiest way to create an `Ember.Component` is via
    a template. If you name a template
    `app/components/my-foo.hbs`, you will be able to use
    `{{my-foo}}` in other templates, which will make
    an instance of the isolated component.
  
    ```app/components/my-foo.hbs
    {{person-profile person=currentUser}}
    ```
  
    ```app/components/person-profile.hbs
    <h1>{{person.title}}</h1>
    <img src={{person.avatar}}>
    <p class='signature'>{{person.signature}}</p>
    ```
  
    You can use `yield` inside a template to
    include the **contents** of any block attached to
    the component. The block will be executed in the
    context of the surrounding context or outer controller:
  
    ```handlebars
    {{#person-profile person=currentUser}}
      <p>Admin mode</p>
      {{! Executed in the controller's context. }}
    {{/person-profile}}
    ```
  
    ```app/components/person-profile.hbs
    <h1>{{person.title}}</h1>
    {{! Executed in the component's context. }}
    {{yield}} {{! block contents }}
    ```
  
    If you want to customize the component, in order to
    handle events or actions, you implement a subclass
    of `Ember.Component` named after the name of the
    component.
  
    For example, you could implement the action
    `hello` for the `person-profile` component:
  
    ```app/components/person-profile.js
    import Ember from 'ember';
  
    export default Ember.Component.extend({
      actions: {
        hello(name) {
          console.log("Hello", name);
        }
      }
    });
    ```
  
    And then use it in the component's template:
  
    ```app/templates/components/person-profile.hbs
    <h1>{{person.title}}</h1>
    {{yield}} <!-- block contents -->
    <button {{action 'hello' person.name}}>
      Say Hello to {{person.name}}
    </button>
    ```
  
    Components must have a `-` in their name to avoid
    conflicts with built-in controls that wrap HTML
    elements. This is consistent with the same
    requirement in web components.
  
  
    ## HTML Tag
  
    The default HTML tag name used for a component's DOM representation is `div`.
    This can be customized by setting the `tagName` property.
    The following component class:
  
    ```app/components/emphasized-paragraph.js
    import Ember from 'ember';
  
    export default Ember.Component.extend({
      tagName: 'em'
    });
    ```
  
    Would result in instances with the following HTML:
  
    ```html
    <em id="ember1" class="ember-view"></em>
    ```
  
  
    ## HTML `class` Attribute
  
    The HTML `class` attribute of a component's tag can be set by providing a
    `classNames` property that is set to an array of strings:
  
    ```app/components/my-widget.js
    import Ember from 'ember';
  
    export default Ember.Component.extend({
      classNames: ['my-class', 'my-other-class']
    });
    ```
  
    Will result in component instances with an HTML representation of:
  
    ```html
    <div id="ember1" class="ember-view my-class my-other-class"></div>
    ```
  
    `class` attribute values can also be set by providing a `classNameBindings`
    property set to an array of properties names for the component. The return value
    of these properties will be added as part of the value for the components's `class`
    attribute. These properties can be computed properties:
  
    ```app/components/my-widget.js
    import Ember from 'ember';
  
    export default Ember.Component.extend({
      classNameBindings: ['propertyA', 'propertyB'],
      propertyA: 'from-a',
      propertyB: Ember.computed(function() {
        if (someLogic) { return 'from-b'; }
      })
    });
    ```
  
    Will result in component instances with an HTML representation of:
  
    ```html
    <div id="ember1" class="ember-view from-a from-b"></div>
    ```
  
    If the value of a class name binding returns a boolean the property name
    itself will be used as the class name if the property is true.
    The class name will not be added if the value is `false` or `undefined`.
  
    ```app/components/my-widget.js
    import Ember from 'ember';
  
    export default Ember.Component.extend({
      classNameBindings: ['hovered'],
      hovered: true
    });
    ```
  
    Will result in component instances with an HTML representation of:
  
    ```html
    <div id="ember1" class="ember-view hovered"></div>
    ```
  
    When using boolean class name bindings you can supply a string value other
    than the property name for use as the `class` HTML attribute by appending the
    preferred value after a ":" character when defining the binding:
  
    ```app/components/my-widget.js
    import Ember from 'ember';
  
    export default Ember.Component.extend({
      classNameBindings: ['awesome:so-very-cool'],
      awesome: true
    });
    ```
  
    Will result in component instances with an HTML representation of:
  
    ```html
    <div id="ember1" class="ember-view so-very-cool"></div>
    ```
  
    Boolean value class name bindings whose property names are in a
    camelCase-style format will be converted to a dasherized format:
  
    ```app/components/my-widget.js
    import Ember from 'ember';
  
    export default Ember.Component.extend({
      classNameBindings: ['isUrgent'],
      isUrgent: true
    });
    ```
  
    Will result in component instances with an HTML representation of:
  
    ```html
    <div id="ember1" class="ember-view is-urgent"></div>
    ```
  
    Class name bindings can also refer to object values that are found by
    traversing a path relative to the component itself:
  
    ```app/components/my-widget.js
    import Ember from 'ember';
  
    export default Ember.Component.extend({
      classNameBindings: ['messages.empty'],
      messages: Ember.Object.create({
        empty: true
      })
    });
    ```
  
    Will result in component instances with an HTML representation of:
  
    ```html
    <div id="ember1" class="ember-view empty"></div>
    ```
  
    If you want to add a class name for a property which evaluates to true and
    and a different class name if it evaluates to false, you can pass a binding
    like this:
  
    ```app/components/my-widget.js
    import Ember from 'ember';
  
    export default Ember.Component.extend({
      classNameBindings: ['isEnabled:enabled:disabled'],
      isEnabled: true
    });
    ```
  
    Will result in component instances with an HTML representation of:
  
    ```html
    <div id="ember1" class="ember-view enabled"></div>
    ```
  
    When isEnabled is `false`, the resulting HTML representation looks like
    this:
  
    ```html
    <div id="ember1" class="ember-view disabled"></div>
    ```
  
    This syntax offers the convenience to add a class if a property is `false`:
  
    ```app/components/my-widget.js
    import Ember from 'ember';
  
    // Applies no class when isEnabled is true and class 'disabled' when isEnabled is false
    export default Ember.Component.extend({
      classNameBindings: ['isEnabled::disabled'],
      isEnabled: true
    });
    ```
  
    Will result in component instances with an HTML representation of:
  
    ```html
    <div id="ember1" class="ember-view"></div>
    ```
  
    When the `isEnabled` property on the component is set to `false`, it will result
    in component instances with an HTML representation of:
  
    ```html
    <div id="ember1" class="ember-view disabled"></div>
    ```
  
    Updates to the value of a class name binding will result in automatic
    update of the  HTML `class` attribute in the component's rendered HTML
    representation. If the value becomes `false` or `undefined` the class name
    will be removed.
    Both `classNames` and `classNameBindings` are concatenated properties. See
    [Ember.Object](/api/classes/Ember.Object.html) documentation for more
    information about concatenated properties.
  
  
    ## HTML Attributes
  
    The HTML attribute section of a component's tag can be set by providing an
    `attributeBindings` property set to an array of property names on the component.
    The return value of these properties will be used as the value of the component's
    HTML associated attribute:
  
    ```app/components/my-anchor.js
    import Ember from 'ember';
  
    export default Ember.Component.extend({
      tagName: 'a',
      attributeBindings: ['href'],
      href: 'http://google.com'
    });
    ```
  
    Will result in component instances with an HTML representation of:
  
    ```html
    <a id="ember1" class="ember-view" href="http://google.com"></a>
    ```
  
    One property can be mapped on to another by placing a ":" between
    the source property and the destination property:
  
    ```app/components/my-anchor.js
    import Ember from 'ember';
  
    export default Ember.Component.extend({
      tagName: 'a',
      attributeBindings: ['url:href'],
      url: 'http://google.com'
    });
    ```
  
    Will result in component instances with an HTML representation of:
  
    ```html
    <a id="ember1" class="ember-view" href="http://google.com"></a>
    ```
  
    Namespaced attributes (e.g. `xlink:href`) are supported, but have to be
    mapped, since `:` is not a valid character for properties in Javascript:
  
    ```app/components/my-use.js
    import Ember from 'ember';
  
    export default Ember.Component.extend({
      tagName: 'use',
      attributeBindings: ['xlinkHref:xlink:href'],
      xlinkHref: '#triangle'
    });
    ```
  
    Will result in component instances with an HTML representation of:
  
    ```html
    <use xlink:href="#triangle"></use>
    ```
  
    If the return value of an `attributeBindings` monitored property is a boolean
    the attribute will be present or absent depending on the value:
  
    ```app/components/my-text-input.js
    import Ember from 'ember';
  
    export default Ember.Component.extend({
      tagName: 'input',
      attributeBindings: ['disabled'],
      disabled: false
    });
    ```
  
    Will result in a component instance with an HTML representation of:
  
    ```html
    <input id="ember1" class="ember-view" />
    ```
  
    `attributeBindings` can refer to computed properties:
  
    ```app/components/my-text-input.js
    import Ember from 'ember';
  
    export default Ember.Component.extend({
      tagName: 'input',
      attributeBindings: ['disabled'],
      disabled: Ember.computed(function() {
        if (someLogic) {
          return true;
        } else {
          return false;
        }
      })
    });
    ```
  
    To prevent setting an attribute altogether, use `null` or `undefined` as the
    return value of the `attributeBindings` monitored property:
  
    ```app/components/my-text-input.js
    import Ember from 'ember';
  
    export default Ember.Component.extend({
      tagName: 'form',
      attributeBindings: ['novalidate'],
      novalidate: null
    });
    ```
  
    Updates to the property of an attribute binding will result in automatic
    update of the  HTML attribute in the component's rendered HTML representation.
    `attributeBindings` is a concatenated property. See [Ember.Object](/api/classes/Ember.Object.html)
    documentation for more information about concatenated properties.
  
  
    ## Layouts
  
    See [Ember.Templates.helpers.yield](/api/classes/Ember.Templates.helpers.html#method_yield)
    for more information.
  
    Layout can be used to wrap content in a component. In addition
    to wrapping content in a Component's template, you can also use
    the public layout API in your Component JavaScript.
  
    ```app/templates/components/person-profile.hbs
      <h1>Person's Title</h1>
      <div class='details'>{{yield}}</div>
    ```
  
    ```app/components/person-profile.js
      import Ember from 'ember';
      import layout from '../templates/components/person-profile';
  
      export default Ember.Component.extend({
        layout
      });
    ```
  
    The above will result in the following HTML output:
  
    ```html
      <h1>Person's Title</h1>
      <div class="details">
        <h2>Chief Basket Weaver</h2>
        <h3>Fisherman Industries</h3>
      </div>
    ```
  
  
    ## Responding to Browser Events
  
    Components can respond to user-initiated events in one of three ways: method
    implementation, through an event manager, and through `{{action}}` helper use
    in their template or layout.
  
  
    ### Method Implementation
  
    Components can respond to user-initiated events by implementing a method that
    matches the event name. A `jQuery.Event` object will be passed as the
    argument to this method.
  
    ```app/components/my-widget.js
    import Ember from 'ember';
  
    export default Ember.Component.extend({
      click(event) {
        // will be called when an instance's
        // rendered element is clicked
      }
    });
    ```
  
  
    ### `{{action}}` Helper
  
    See [Ember.Templates.helpers.action](/api/classes/Ember.Templates.helpers.html#method_action).
  
  
    ### Event Names
  
    All of the event handling approaches described above respond to the same set
    of events. The names of the built-in events are listed below. (The hash of
    built-in events exists in `Ember.EventDispatcher`.) Additional, custom events
    can be registered by using `Ember.Application.customEvents`.
  
    Touch events:
  
    * `touchStart`
    * `touchMove`
    * `touchEnd`
    * `touchCancel`
  
    Keyboard events:
  
    * `keyDown`
    * `keyUp`
    * `keyPress`
  
    Mouse events:
  
    * `mouseDown`
    * `mouseUp`
    * `contextMenu`
    * `click`
    * `doubleClick`
    * `mouseMove`
    * `focusIn`
    * `focusOut`
    * `mouseEnter`
    * `mouseLeave`
  
    Form events:
  
    * `submit`
    * `change`
    * `focusIn`
    * `focusOut`
    * `input`
  
    HTML5 drag and drop events:
  
    * `dragStart`
    * `drag`
    * `dragEnter`
    * `dragLeave`
    * `dragOver`
    * `dragEnd`
    * `drop`
  
    @class Component
    @namespace Ember
    @extends Ember.CoreView
    @uses Ember.TargetActionSupport
    @uses Ember.ClassNamesSupport
    @uses Ember.ActionSupport
    @uses Ember.ViewMixin
    @uses Ember.ViewStateSupport
    @public
  */
  var Component = _emberViews.CoreView.extend(_emberViews.ChildViewsSupport, _emberViews.ViewStateSupport, _emberViews.ClassNamesSupport, _emberRuntime.TargetActionSupport, _emberViews.ActionSupport, _emberViews.ViewMixin, (_CoreView$extend = {
    isComponent: true,

    init: function () {
      var _this = this;

      this._super.apply(this, arguments);
      this[IS_DISPATCHING_ATTRS] = false;
      this[DIRTY_TAG] = new _reference.DirtyableTag();
      this[ROOT_REF] = new _references.RootReference(this);
      this[BOUNDS] = null;

      // If a `defaultLayout` was specified move it to the `layout` prop.
      // `layout` is no longer a CP, so this just ensures that the `defaultLayout`
      // logic is supported with a deprecation
      if (this.defaultLayout && !this.layout) {
        (true && !(false) && (0, _emberDebug.deprecate)('Specifying `defaultLayout` to ' + this + ' is deprecated. Please use `layout` instead.', false, {
          id: 'ember-views.component.defaultLayout',
          until: '3.0.0',
          url: 'https://emberjs.com/deprecations/v2.x/#toc_ember-component-defaultlayout'
        }));


        this.layout = this.defaultLayout;
      }

      // If in a tagless component, assert that no event handlers are defined
      (true && !(this.tagName !== '' || !this.renderer._destinedForDOM || !function () {
        var eventDispatcher = (0, _emberUtils.getOwner)(_this).lookup('event_dispatcher:main');
        var events = eventDispatcher && eventDispatcher._finalEvents || {};

        for (var key in events) {
          var methodName = events[key];

          if (typeof _this[methodName] === 'function') {
            return true; // indicate that the assertion should be triggered
          }
        }
      }()) && (0, _emberDebug.assert)('You can not define a function that handles DOM events in the `' + this + '` tagless component since it doesn\'t have any DOM element.', this.tagName !== '' || !this.renderer._destinedForDOM || !function () {
        var eventDispatcher = (0, _emberUtils.getOwner)(_this).lookup('event_dispatcher:main');var events = eventDispatcher && eventDispatcher._finalEvents || {};for (var key in events) {
          var methodName = events[key];if (typeof _this[methodName] === 'function') {
            return true;
          }
        }
      }()));
      (true && !(!(this.tagName && this.tagName.isDescriptor)) && (0, _emberDebug.assert)('You cannot use a computed property for the component\'s `tagName` (' + this + ').', !(this.tagName && this.tagName.isDescriptor)));
    },
    rerender: function () {
      this[DIRTY_TAG].dirty();
      this._super();
    },
    __defineNonEnumerable: function (property) {
      this[property.name] = property.descriptor.value;
    }
  }, _CoreView$extend[_emberMetal.PROPERTY_DID_CHANGE] = function (key) {
    if (this[IS_DISPATCHING_ATTRS]) {
      return;
    }

    var args = void 0,
        reference = void 0;

    if ((args = this[ARGS]) && (reference = args[key])) {
      if (reference[_references.UPDATE]) {
        reference[_references.UPDATE]((0, _emberMetal.get)(this, key));
      }
    }
  }, _CoreView$extend.getAttr = function (key) {
    // TODO Intimate API should be deprecated
    return this.get(key);
  }, _CoreView$extend.readDOMAttr = function (name) {
    var element = (0, _emberViews.getViewElement)(this);
    return (0, _runtime.readDOMAttr)(element, name);
  }, _CoreView$extend));

  Component[_emberUtils.NAME_KEY] = 'Ember.Component';

  Component.reopenClass({
    isComponentFactory: true,
    positionalParams: []
  });

  exports.default = Component;
});