define('ember-modal-dialog/components/modal-dialog', ['exports', 'ember-modal-dialog/templates/components/modal-dialog'], function (exports, _modalDialog) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var VALID_OVERLAY_POSITIONS = ['parent', 'sibling'];

  exports.default = Ember.Component.extend({
    tagName: '',
    layout: _modalDialog.default,
    modalService: Ember.inject.service('modal-dialog'),
    destinationElementId: Ember.computed.oneWay('modalService.destinationElementId'),
    modalDialogComponentName: Ember.computed('renderInPlace', 'tetherTarget', 'animatable', 'hasLiquidWormhole', 'hasLiquidTether', function () {
      var tetherTarget = this.get('tetherTarget');
      var hasLiquidTether = this.get('hasLiquidTether');
      var hasLiquidWormhole = this.get('hasLiquidWormhole');
      var animatable = this.get('animatable');

      if (this.get('renderInPlace')) {
        return 'ember-modal-dialog/-in-place-dialog';
      } else if (tetherTarget && hasLiquidTether && hasLiquidWormhole && animatable === true) {
        return 'ember-modal-dialog/-liquid-tether-dialog';
      } else if (tetherTarget) {
        this.ensureEmberTetherPresent();
        return 'ember-modal-dialog/-tether-dialog';
      } else if (hasLiquidWormhole && animatable === true) {
        return 'ember-modal-dialog/-liquid-dialog';
      }
      return 'ember-modal-dialog/-basic-dialog';
    }),
    animatable: null,
    hasLiquidWormhole: Ember.computed.readOnly('modalService.hasLiquidWormhole'),
    hasLiquidTether: Ember.computed.readOnly('modalService.hasLiquidTether'),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (true /* DEBUG */) {
        this.validateProps();
      }
    },
    validateProps: function validateProps() {
      var overlayPosition = this.get('overlayPosition');
      if (VALID_OVERLAY_POSITIONS.indexOf(overlayPosition) === -1) {
        (true && Ember.warn('overlayPosition value \'' + overlayPosition + '\' is not valid (valid values [' + VALID_OVERLAY_POSITIONS.join(', ') + '])', false, { id: 'ember-modal-dialog.validate-overlay-position' }));
      }
    },

    // onClose - set this from templates

    // containerClass - set this from templates
    containerClassNames: ['ember-modal-dialog'], // set this in a subclass definition

    // overlayClass - set this from templates
    overlayClassNames: ['ember-modal-overlay'], // set this in a subclass definition

    // wrapperClass - set this from templates
    wrapperClassNames: ['ember-modal-wrapper'], // set this in a subclass definition

    concatenatedProperties: ['containerClassNames', 'overlayClassNames', 'wrapperClassNames'],

    hasOverlay: true,
    translucentOverlay: false,
    overlayPosition: 'parent', // `parent` or `sibling`
    clickOutsideToClose: false,
    renderInPlace: false,
    tetherTarget: null,
    stack: Ember.computed.oneWay('elementId'), // pass a `stack` string to set a "stack" to be passed to liquid-wormhole / liquid-tether
    value: 0, // pass a `value` to set a "value" to be passed to liquid-wormhole / liquid-tether

    targetAttachment: 'middle center',
    tetherClassPrefix: null,
    attachmentClass: Ember.computed('attachment', function () {
      var attachment = this.get('attachment');
      if (Ember.isEmpty(attachment)) {
        return;
      }
      return attachment.split(' ').map(function (attachmentPart) {
        return 'emd-attachment-' + Ember.String.dasherize(attachmentPart);
      }).join(' ');
    }),
    targetAttachmentClass: Ember.computed('targetAttachment', function () {
      var targetAttachment = this.get('targetAttachment') || '';
      // Convert tether-styled values like 'middle right' to 'right'
      targetAttachment = targetAttachment.split(' ').slice(-1)[0];
      return 'ember-modal-dialog-target-attachment-' + Ember.String.dasherize(targetAttachment) + ' emd-target-attachment-' + Ember.String.dasherize(targetAttachment);
    }),
    ensureEmberTetherPresent: function ensureEmberTetherPresent() {
      if (!this.get('modalService.hasEmberTether')) {
        throw new Error('Please install ember-tether in order to pass a tetherTarget to modal-dialog');
      }
    },

    actions: {
      onClose: function onClose() {
        this.sendAction('onClose');
      },
      onClickOverlay: function onClickOverlay(e) {
        e.preventDefault();
        if (this.get('onClickOverlay')) {
          this.sendAction('onClickOverlay');
        } else {
          this.sendAction('onClose');
        }
      }
    }
  });
});