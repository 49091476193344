enifed('ember-template-compiler/plugins/transform-input-on-to-onEvent', ['exports', 'ember-debug', 'ember-template-compiler/system/calculate-location-display'], function (exports, _emberDebug, _calculateLocationDisplay) {
  'use strict';

  exports.default = transformInputOnToOnEvent;


  /**
   @module ember
   @submodule ember-htmlbars
  */

  /**
    An HTMLBars AST transformation that replaces all instances of
  
    ```handlebars
   {{input on="enter" action="doStuff"}}
   {{input on="key-press" action="doStuff"}}
    ```
  
    with
  
    ```handlebars
   {{input enter="doStuff"}}
   {{input key-press="doStuff"}}
    ```
  
    @private
    @class TransformInputOnToOnEvent
  */
  function transformInputOnToOnEvent(env) {
    var b = env.syntax.builders;
    var moduleName = env.meta.moduleName;


    return {
      name: 'transform-input-on-to-onEvent',

      visitors: {
        MustacheStatement: function (node) {
          if (node.path.original !== 'input') {
            return;
          }

          var action = hashPairForKey(node.hash, 'action');
          var on = hashPairForKey(node.hash, 'on');
          var onEvent = hashPairForKey(node.hash, 'onEvent');

          if (!action && !on && !onEvent) {
            return;
          }

          var normalizedOn = on || onEvent;
          var moduleInfo = (0, _calculateLocationDisplay.default)(moduleName, node.loc);

          if (normalizedOn && normalizedOn.value.type !== 'StringLiteral') {
            (true && !(false) && (0, _emberDebug.deprecate)('Using a dynamic value for \'#{normalizedOn.key}=\' with the \'{{input}}\' helper ' + moduleInfo + 'is deprecated.', false, { id: 'ember-template-compiler.transform-input-on-to-onEvent.dynamic-value', until: '3.0.0' }));


            normalizedOn.key = 'onEvent';
            return; // exit early, as we cannot transform further
          }

          removeFromHash(node.hash, normalizedOn);
          removeFromHash(node.hash, action);

          if (!action) {
            (true && !(false) && (0, _emberDebug.deprecate)('Using \'{{input ' + normalizedOn.key + '="' + normalizedOn.value.value + '" ...}}\' without specifying an action ' + moduleInfo + 'will do nothing.', false, { id: 'ember-template-compiler.transform-input-on-to-onEvent.no-action', until: '3.0.0' }));


            return; // exit early, if no action was available there is nothing to do
          }

          var specifiedOn = normalizedOn ? normalizedOn.key + '="' + normalizedOn.value.value + '" ' : '';
          if (normalizedOn && normalizedOn.value.value === 'keyPress') {
            // using `keyPress` in the root of the component will
            // clobber the keyPress event handler
            normalizedOn.value.value = 'key-press';
          }

          var expected = (normalizedOn ? normalizedOn.value.value : 'enter') + '="' + action.value.original + '"';

          (true && !(false) && (0, _emberDebug.deprecate)('Using \'{{input ' + specifiedOn + 'action="' + action.value.original + '"}}\' ' + moduleInfo + 'is deprecated. Please use \'{{input ' + expected + '}}\' instead.', false, { id: 'ember-template-compiler.transform-input-on-to-onEvent.normalized-on', until: '3.0.0' }));

          if (!normalizedOn) {
            normalizedOn = b.pair('onEvent', b.string('enter'));
          }

          node.hash.pairs.push(b.pair(normalizedOn.value.value, action.value));
        }
      }
    };
  }

  function hashPairForKey(hash, key) {
    for (var i = 0; i < hash.pairs.length; i++) {
      var pair = hash.pairs[i];
      if (pair.key === key) {
        return pair;
      }
    }

    return false;
  }

  function removeFromHash(hash, pairToRemove) {
    var newPairs = [];
    for (var i = 0; i < hash.pairs.length; i++) {
      var pair = hash.pairs[i];

      if (pair !== pairToRemove) {
        newPairs.push(pair);
      }
    }

    hash.pairs = newPairs;
  }
});