enifed('ember-template-compiler/plugins/transform-each-in-into-each', ['exports'], function (exports) {
  'use strict';

  exports.default = transformEachInIntoEach;
  /**
   @module ember
   @submodule ember-glimmer
  */

  /**
    A Glimmer2 AST transformation that replaces all instances of
  
    ```handlebars
   {{#each-in iterableThing as |key value|}}
    ```
  
    with
  
    ```handlebars
   {{#each (-each-in iterableThing) as |key value|}}
    ```
  
    @private
    @class TransformHasBlockSyntax
  */
  function transformEachInIntoEach(env) {
    var b = env.syntax.builders;


    return {
      name: 'transform-each-in-into-each',

      visitors: {
        BlockStatement: function (node) {
          if (node.path.original === 'each-in') {
            node.params[0] = b.sexpr(b.path('-each-in'), [node.params[0]]);
            return b.block(b.path('each'), node.params, node.hash, node.program, node.inverse, node.loc);
          }
        }
      }
    };
  }
});